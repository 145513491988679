exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-firmie-js": () => import("./../../../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-unit-dato-cms-unit-unit-slug-jsx": () => import("./../../../src/pages/unit/{datoCmsUnit.unitSlug}.jsx" /* webpackChunkName: "component---src-pages-unit-dato-cms-unit-unit-slug-jsx" */),
  "component---src-pages-unity-js": () => import("./../../../src/pages/unity.js" /* webpackChunkName: "component---src-pages-unity-js" */)
}

